/* You can add global styles to this file, and also import other style files */



body {
  background-color: #F0F0F0 !important;
  font-family: 'Lato', sans-serif !important;
}


p, h1, h2, h3{
   
   font-family: 'Nunito', sans-serif !important;
}

.titulo {
	font-family: 'Nunito', sans-serif !important;	
	font-style: stroke;
}


.custom-card-header-bg {
  background-color: #D5DBDB !important;
}